import styled from 'styled-components';
import { Link } from 'gatsby';

export const GalleryLink = styled(Link)`
  text-decoration: none;
  display: block;
  margin-bottom: 35px;
  position: relative;
  text-align: center;

  img {
    transition: all .4s linear;
    margin: 0;
    border-radius: 6px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  &:hover {
    img {
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
    }

    div {
      background: rgba(49, 52, 63, .9);
    }
  }
`;

export const AlbumTitle = styled.div`
  transition: all .4s linear;
  position: absolute;
  top: 7px;
  left: 7px;
  display: inline-block;
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  background: rgba(49, 52, 63, .6);
  padding: 2px 10px;
  border-radius: 6px;
`;
