/* eslint-disable import/first */
typeof window !== 'undefined' && require('intersection-observer');

import React, { useState } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { graphql } from 'gatsby';
import { LazyImage } from 'react-lazy-images';
import { Lock } from 'react-feather';
import styled from 'styled-components';

import Layout from '../components/Layout';
import Breaker from '../components/Breaker';
import SEO from '../components/seo';
import { Title } from '../styles/base';
import { GalleryLink, AlbumTitle } from '../styles/gallery';
import Loader from '../images/Loader.svg';
import { GALLERY_PATH } from '../constants/Routes';
import { KEYWORDS } from '../constants/Keywords';

const SearchInput = styled.input`
  font-size: 13px;
  border: 1px #ddd solid;
  width: 100%;
  display: block;
  padding: 8px 15px;
  outline: 0;
  border-radius: 3px;
  margin: 2px 0 0;

  &:focus {
    border-color: #9b87dd;
  }
`;

const Galeria = ({ data }) => {
  const [search, setSearch] = useState();
  const mapData = () => {
    if (search) {
      const searchRegEx = new RegExp(search.toLowerCase(), 'g');

      return data && data.galleries.edges.filter(({ node }) => node.frontmatter.title.toLowerCase().match(searchRegEx));
    } else {
      return data && data.galleries.edges;
    }
  }

  return (
    <Layout>
      <SEO title="Galeria Privada" keywords={[...KEYWORDS]} />

      <Grid>
        <Row>
          <Col md={4} />
          <Col md={4}>
            <Title>Galeria Privada</Title>
          </Col>
          <Col md={4}>
            <SearchInput type="text" onChange={({ target: { value } }) => setSearch(value)} placeholder="Procure a sua galeria. Ex.: Miguel" />
          </Col>
        </Row>
      </Grid>

      <Breaker />

      <Grid>
        <Row>
          {mapData().map(({ node }) => {
            const {
              id,
              frontmatter: {
                title,
                cover,
                code
              },
              fields: {
                slug
              }
            } = node;

            const accessToken = typeof localStorage !== 'undefined' && localStorage.getItem(id);
            const hasAccess = accessToken && code === accessToken;

            return (
              <Col md={4} key={id}>
                <GalleryLink to={`${GALLERY_PATH}${slug}`}>
                  <LazyImage
                    src={cover}
                    alt={`Galeria Privada ${slug}`}
                    placeholder={({ imageProps, ref }) => (
                      <div style={{ padding: '80px 0' }} ref={ref}>
                        <Loader />
                      </div>
                    )}
                    actual={({ imageProps }) => <img {...imageProps} alt={imageProps.alt} />}
                  />

                  <AlbumTitle>
                    {!hasAccess &&
                      <Lock
                        size={13}
                        style={{
                          verticalAlign: 'middle',
                          margin: '-4.5px 5px 0 0'
                        }}
                      />
                    }
                    {title}
                  </AlbumTitle>
                </GalleryLink>
              </Col>
            )
          })}
        </Row>
      </Grid>
    </Layout>
  );
}

export const query = graphql`
  query {
    galleries: allMarkdownRemark(
      filter: { fields: { collection: { eq: "galleries" } } },
      sort: { order: ASC, fields: [frontmatter___title]}
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            cover,
            code
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default Galeria;
